import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { thumb7 } from "../assets/images";

const MorePage = () => {
  const items = ["Culture", "Whitepaper", "Blogs", "Case Study"];
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); 
  };

  return (
    <div>
      <button onClick={handleBackClick} className="back-button" style={{marginBottom:"10px", marginTop:"82px"}}>
        Back
      </button>
      <div className="card-container-nodepage">
        {items.map((item) => (
          <div key={item} className="card-nodepage">
            <Link to={`/node/${item.toLowerCase()}`} className="card-link">
              <img
                src={thumb7}
                alt={item}
                width="30px"
                height="30px"
                style={{ height: "100px", width: "100px" }}
              />
              <div className="card-title-nodepage">{item}</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MorePage;
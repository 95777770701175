import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../authConfig"; 

const msalInstance = new PublicClientApplication(msalConfig);

export const fetchDetails = () => async (dispatch) => {
  dispatch({ type: "FETCH_DETAILS_REQUEST" });

  try {
    await msalInstance.initialize();

    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      throw new Error("No accounts found. User needs to log in.");
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    });

    const token = response.accessToken;
    console.log("Acquired Token:", token);

    const [listResponse, jsonResponse, sasResponse] = await Promise.all([
      axios.get("https://appdevfunctionapp.azurewebsites.net/api/GetListOfPaths?", {
        headers: { Authorization: `Bearer ${token}` }
      }),
      axios.get("https://appdevfunctionapp.azurewebsites.net/api/GetValuesFromJSON?", {
        headers: { Authorization: `Bearer ${token}` }
      }),
      axios.get("https://appdevfunctionapp.azurewebsites.net/api/GetSaasURL?", {
        headers: { Authorization: `Bearer ${token}` }
      })
    ]);

    const listPaths = listResponse.data;
    const metadata = jsonResponse.data;
    const sasUrls = sasResponse.data;

    const metadataPaths = new Set(metadata.map((item) => item.path));
    const missingPaths = listPaths.filter((path) => !metadataPaths.has(path));

    const modifiedData = [
      ...metadata,
      ...missingPaths.map((path) => ({
        path,
        name_of_document: path.split("/").pop(),
        summary: "No summary available",
        timestamp: new Date().toISOString(),
        author_name: "Unknown",
        fullPath: `https://appdevstor.blob.core.windows.net/${path}`,
      })),
    ];

    dispatch({ type: "FETCH_DETAILS_SUCCESS", payload: { data: modifiedData, sasUrls } });
  } catch (error) {
    console.error("Error fetching details:", error.message);
    dispatch({ type: "FETCH_DETAILS_FAILURE", payload: error.message });
  }
};
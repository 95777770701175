import React from "react";

const ServiceCard = ({ iconClass, title, description }) => (
  <div className="col-md-4">
    <div className="service-card">
      <i className={`${iconClass} display-4 mb-3`}></i>
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
  </div>
);

export default ServiceCard;
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../App.css";
import { thumb7, fileIcon } from "../assets/images";

const NodePage = ({ data }) => {
  const { parentPath } = useParams();
  const navigate = useNavigate();
  const { data: metadata, sasUrls } = data;
  const [nodes, setNodes] = useState([]);
  const [history, setHistory] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const baseUrl = "https://appdevstor.blob.core.windows.net/";
  const [fullPath, setFullPath] = useState(baseUrl);

  useEffect(() => {
    if (metadata && metadata.length > 0) {
      console.log("Metadata updated:", metadata);
      setNodes([]);
      setFullPath(
        parentPath ? `${baseUrl}${parentPath.replace(/ /g, "%20")}` : baseUrl
      );
      setHistory(parentPath ? parentPath.split("/") : []);
      if (parentPath) {
        handleCardClick(metadata, parentPath, true);
      }
    }
  }, [metadata, parentPath]);

  const handleCardClick = (urls, filterString, isInitial = false) => {
    if (!urls) return;
    console.log("Handling card click:", filterString);
    const clickedFile = urls.find(
      (item) => item.path && item.path.endsWith(filterString)
    );
    if (clickedFile && isFile(filterString)) {
      const matchedSasUrl = sasUrls.find(
        (item) => item.blob_path === clickedFile.path
      );
      if (matchedSasUrl) {
        console.log("File selected:", matchedSasUrl.sas_url);
        setSelectedFile(matchedSasUrl.sas_url);
        console.log("Current SAS URL:", matchedSasUrl.sas_url);
      }
      return;
    }

    const filteredUrls = urls.filter(
      (item) => item.path && item.path.includes(filterString)
    );
    const transformedUrls = filteredUrls.map((item) => {
      const index = item.path.indexOf(filterString) + filterString.length;
      return item.path.slice(index + 1);
    });
    const resultSet = new Set(
      transformedUrls.map((url) => url.split("/")[0]).filter(Boolean)
    );
    console.log("Nodes set:", Array.from(resultSet));
    setNodes(Array.from(resultSet));
    if (!isInitial) {
      setHistory((prevHistory) => {
        if (prevHistory[prevHistory.length - 1] !== filterString) {
          return [...prevHistory, filterString];
        }
        return prevHistory;
      });
      setFullPath((prevFullPath) => {
        const newPath = `${prevFullPath}/${filterString}`.replace(/ /g, "%20");
        console.log("Current path:", newPath);
        return newPath;
      });
    }
    setSelectedFile(null);
  };

  const handleNodeClick = (node) => {
    console.log("Node clicked:", node);
    handleCardClick(metadata, node);
  };

  const handleBackClick = () => {
    console.log("Back button clicked");
    setSelectedFile(null);
    if (history.length > 1) {
      const newHistory = [...history];
      newHistory.pop();
      const previousPath = newHistory.join("/");
      setHistory(newHistory);
      navigate(`/node/${previousPath}`);
      handleCardClick(metadata, previousPath, true);
    } else {
      navigate("/");
      setFullPath(baseUrl);
    }
  };

  const isFile = (node) => {
    const fileExtensions = [".pdf", ".pptx", ".docx", ".xlsx", ".doc"];
    return fileExtensions.some((extension) => node.endsWith(extension));
  };

  return (
    <div>
      <button onClick={handleBackClick} className="back-button" style={{marginBottom:"10px",marginTop:"82px"}}>
        Back
      </button>
      {selectedFile ? (
        <div className="file-viewer">
          <embed
            src={selectedFile}
            type="application/pdf"
            width="100%"
            height="600px"
          />
        </div>
      ) : (
        <div className="card-container-nodepage">
          {nodes.map((node) => {
            const nodeData = metadata.find((item) => item.path.includes(node));
            return (
              <div
                key={node}
                onClick={() => handleNodeClick(node)}
                className="card-nodepage"
              >
                <img
                  src={isFile(node) ? fileIcon : thumb7}
                  alt="Node"
                  width="30px"
                  height="30px"
                  style={{ height: "100px", width: "100px" }}
                />
                <div className="card-title-nodepage">{node}</div>
                {nodeData && (
                  <div className="node-details">
                    <p>{nodeData.summary}</p>
                    {isFile(node) && (
                      <>
                        <p><strong>Author:</strong> {nodeData.author_name}</p>
                      
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NodePage;
import React from "react";
import { YashLogo } from "../assets/images";
import { Link } from "react-router-dom";
import "../App.css";
import { useMsal } from "@azure/msal-react";

const Navbar = () => {
  const { instance, accounts } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect().catch((e) => console.error("Logout failed", e));
  };

  const getUsername = (email) => {
    return email.split("@")[0];
  };

  return (
    <header>
      <nav className="navbar-main">
        <div className="main-container d-flex align-items-center">
          
            <img
              src={YashLogo}
              alt="Yash"
              width="70"
              className="navbar-brand1"
              style={{ marginTop: "11px" , marginBottom:"10px" }}
            />
          
          <Link to="/" className="navbar-brand1">
            HOME
          </Link>
          <Link to="/node/awsappdev" className="navbar-brand1">
            AWSAppDev
          </Link>
          <Link to="/node/technologies" className="navbar-brand1">
            Technologies
          </Link>

          <Link to="/more" className="navbar-brand1">
            More
          </Link>
          <div style={{marginLeft:"390px"}}></div>
          {accounts.length > 0 && (
            <>
              <span className="navbar-brand1">
                {getUsername(accounts[0]?.username)}
              </span>
              <button className="login-button" onClick={handleLogout}>
                Logout
              </button>
            </>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
import React from "react";

const ClientCard = ({ imgSrc, altText, clientName }) => (
  <div className="col-md-3">
    <div className="client-card">
      <img src={imgSrc} alt={altText} className="client-img mb-2" />
      <h5>{clientName}</h5>
    </div>
  </div>
);

export default ClientCard;
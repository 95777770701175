import React, { useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store';
import Navbar from './components/Navbar';
import NodePage from './components/NodePage';
import HomePage from './components/HomePage';
import MorePage from './components/MorePage';
import Hackathon from './components/Hackathon';
import CulturePage from './components/CulturePage'
import { msalConfig, loginRequest } from './authConfig';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { fetchDetails } from './actions/dataActions';

const msalInstance = new PublicClientApplication(msalConfig);

const AppContent = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isInitialized, setIsInitialized] = useState(false);
  const [token, setToken] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data); 

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await instance.initialize();
        await instance.handleRedirectPromise();
        setIsInitialized(true);
      } catch (e) {
        console.error("Initialization failed", e);
      }
    };
    initializeMsal();
  }, [instance]);

  useEffect(() => {
    const acquireToken = async () => {
      if (isAuthenticated && accounts.length > 0 && !token) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
          });
          console.log("Access Token:", response.accessToken);
          setToken(response.accessToken);
        } catch (e) {
          console.error("Token acquisition failed", e);
        }
      }
    };
    acquireToken();
  }, [isAuthenticated, accounts, instance, token]);

  useEffect(() => {
    if (token) {
      dispatch(fetchDetails(token));
    }
  }, [token, dispatch]);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    instance.loginRedirect(loginRequest);
    return null; 
  }

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage data={data} />} />
        <Route path="/more" element={<MorePage />} />
        <Route path="/node/:parentPath/*" element={<NodePage data={data} />} />
        <Route path='/hackathon' element={<Hackathon/>}/>
        <Route path="/node/culture" element={<CulturePage />}/>
      </Routes>
    </>
  );
};

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <Router>
          <AppContent />
        </Router>
      </Provider>
    </MsalProvider>
  );
}

export default App;
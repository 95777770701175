import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import { thumb7 } from "../assets/images";

const CulturePage = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="card-container-homepage">
      <button
        onClick={handleBackClick}
        className="back-button"
        style={{ marginBottom: "10px", marginTop: "82px" }}
      >
        Back
      </button>
      <div
        className="card-nodepage"
        style={{ marginLeft: "400px", marginTop: "40px" }}
      >
        <Link to="/hackathon" className="card-link">
          <img
            src={thumb7}
            alt="Hackathon"
            width="30px"
            height="30px"
            style={{ height: "100px", width: "100px" }}
          />
          <div className="card-title-nodepage">Hackathon</div>
          <p>Click to Participate in Hackathon</p>
        </Link>
      </div>
    </div>
  );
};

export default CulturePage;

import React from "react";
import "../App.css";
import sponsor1 from "../assets/images/Jd.png";
import sponsor2 from "../assets/images/rlus.png";
import hackathon from "../assets/images/hackk.jpg";
import robot from "../assets/images/robot.png"; 


const Hackathon = () => {
  return (
    <div class="hackathon-container">
      <div class="stars"></div>
      <div class="stars2"></div>
      <section className="hero-hackathon">
        <div className="hero-overlay-hackathon">
          <h2 className="animate-slide-in">The Largest API Hackathon</h2>
          <p className="animate-fade-in">RS 2500 Prize Pool</p>
          <section className="registration-hackathon">
            <a
              href="https://www.yash.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="register-btn animate-bounce"
            >
              Register on Yash Hackathon
            </a>
          </section>
        </div>
      </section>
      {/* Image Section */}
      <div className="image-section-hackathon">
        <img src={hackathon} alt="" className="img-fluid-hackathon" />
      </div>

      <section className="sponsors-hackathon">
        <h3 className="animate-slide-in">Sponsors</h3>
        <div className="sponsor-grid">
          <img src={sponsor1} alt="Sponsor 1" className="animate-zoom-in" />
          <img src={sponsor2} alt="Sponsor 2" className="animate-zoom-in" />
          <img src={sponsor1} alt="Sponsor 3" className="animate-zoom-in" />
          <img src={sponsor2} alt="Sponsor 4" className="animate-zoom-in" />
        </div>
      </section>

      <section className="schedule">
        <h2 className="animate-slide-in">2025 Schedule</h2>
        <p className="animate-fade-in">Stay tuned for updates!</p>
      </section>

      <section className="about-hackathon">
        <div className="about-left animate-slide-in">
          <h2>About the Hackathon</h2>
        </div>
        <div className="about-right animate-fade-in">
          <p>
            Join us for an exciting event where developers from around the world
            come together to create innovative solutions using the latest API
            and cloud technologies. Whether you're a seasoned pro or a newcomer,
            there's something for everyone!
          </p>
        </div>
      </section>

      <section className="faq-hackathon">
        <h2 className="animate-slide-in">Frequently Asked Questions</h2>
        <div className="faq-grid">
          <div className="faq-card animate-fade-in">
            <h3>What is the hackathon about?</h3>
            <p>
              It's a competition where developers build projects using APIs and
              cloud services.
            </p>
          </div>
          <div className="faq-card animate-fade-in">
            <h3>Who can participate?</h3>
            <p>Anyone with an interest in coding and technology can join!</p>
          </div>
          <div className="faq-card animate-fade-in">
            <h3>How do I register?</h3>
            <p>Click the "Register on Devpost" button above to sign up.</p>
          </div>
        </div>
      </section>

      <footer className="footer-hackathon">
        <p>&copy; 2023 API World & Cloud Hackathon. All rights reserved.</p>
        <p>
          Follow us on
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Twitter
          </a>
          ,
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Facebook
          </a>
          , and
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            LinkedIn
          </a>
          .
        </p>
      </footer>

      {/* Add robots */}
      <img src={robot} alt="Robot Left" className="robot-left" />
      <img src={robot} alt="Robot Right" className="robot-right" />
    </div>
  );
};

export default Hackathon;